import { render } from 'preact';
import { format, intervalToDuration, isAfter } from 'date-fns';
import { useState, useEffect } from 'preact/hooks';
import locale from 'date-fns/locale/nb';
import { hasSession } from '../../auth/schibsted-account';
import Carousell from './carousell';
import { getAccess, trackClick } from './utils';
import s from '../../../sass/widgets/_vgtv-premium-sport.module.scss';

const playlist = `https://playlist.stream.schibsted.media/vgtv/N9n5OqSiKillUrvHy5LXhXA6?appName=frimand-front&limit=10&additional=metadata`;

async function getFeed() {
    const req = await fetch(playlist);

    if (!req.ok) {
        console.warn('vgtv-premium-sport: No feed data');
        return [];
    }

    const resp = await req.json();

    // remove past events
    const now = new Date();
    const result = resp.resolved.filter((match) =>
        isAfter(new Date(match.flightTimes.start * 1000), now),
    );

    // return max 5 and order by starttime
    return result
        .slice(0, 5)
        .sort((a, b) => a.flightTimes.start - b.flightTimes.start);
}

function Countdown({ duration }) {
    if (duration != '?') {
        const map = {
            years: 'år',
            months: 'mnd',
            days: 'dag',
            hours: 'tim',
            minutes: 'min',
            seconds: 'sek',
        };

        return (
            <div class={s.countdown}>
                {Object.keys(duration).map((word) => (
                    <>
                        <div key={word} class={s.part}>
                            <div class={s.number}>{duration[word]}</div>
                            <div class={s.word}>{map[word]}</div>
                        </div>
                        <span class={s.delimiter}>{':'}</span>
                    </>
                ))}
            </div>
        );
    }
    return <div></div>;
}

function LiveSportAsset({ access, asset }) {
    const source = 'vgfront';

    const formatDate = (date, fmt = 'd. LLLL H:mm') => {
        try {
            return format(date * 1000, fmt, { locale });
        } catch (error) {
            console.error(error);
            return '?';
        }
    };

    const timeToNow = (date) => {
        try {
            let { days, hours, minutes, seconds } = intervalToDuration({
                start: date * 1000,
                end: new Date().getTime(),
            });

            return { days, hours, minutes, seconds };
        } catch (error) {
            console.error(error);
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
    };

    const [timer, setTimer] = useState(timeToNow(asset.flightTimes.start));

    useEffect(() => {
        const int = setInterval(() => {
            setTimer(timeToNow(asset.flightTimes.start));
        }, 1000);

        return () => clearInterval(int);
    });

    return (
        <>
            {asset.flightTimes && (
                <div class={s.timer}>
                    <div class={s.date}>
                        {formatDate(asset.flightTimes.start)}
                    </div>
                    <Countdown duration={timer} />
                </div>
            )}
            <a
                onClick={() => trackClick('premium-sport', asset.id)}
                href={`https://www.vgtv.no/video/${asset.id}?utm_source=${source}&utm_content=pluss`}
                class="titles"
                id={'asset_' + asset.id}
            >
                <span class="section">{asset.category.title}</span>
                <h3 class="headline">{asset.title}</h3>
                <div class="article-meta">
                    <p class="subscription">
                        {access ? (
                            <span>VG+ SPORT</span>
                        ) : (
                            <span>KJØP VG+ SPORT</span>
                        )}
                    </p>
                </div>
            </a>
        </>
    );
}

export default async function init() {
    let access = false;

    try {
        const spidUser = await hasSession();
        access = await getAccess(spidUser);
    } catch {
        // empty catch
    }

    const feed = await getFeed();

    render(
        <>
            {feed.length ? (
                <div class={s.placeholder + ' card'}>
                    <Carousell
                        feed={feed}
                        access={access}
                        type="Kommende sendinger"
                        content={LiveSportAsset}
                        placeholder={'.' + s.placeholder}
                    />
                </div>
            ) : (
                <div></div>
            )}
        </>,
        document.querySelector('[data-article-type="live-sport"]'),
    );
}
