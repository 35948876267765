import { render } from 'preact';
import { trackEngagement } from '../tracking/pulse';
import { hasSession } from '../auth/schibsted-account';
import UserSettings from '../utils/UserSettings';

const onInView = (elm, opts) =>
    new Promise((resolve) => {
        const obs = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                resolve();
                obs.disconnect();
            }
        }, opts);
        obs.observe(elm);
    });

const Article = ({ article, width, style }) => {
    const {
        title,
        titleLines,
        subTitle,
        url: articleUrl,
        imageUrls = [],
        skin,
    } = article;

    const url = new URL(articleUrl);
    url.search = '?utm_source=mestlest-personalized';

    return (
        <article
            className={`article article-width-${width} has-article-image has-full-width-image skin-${skin}`}
            style={style}
        >
            <div className="article-container">
                <a href={url}>
                    <figure>
                        <div style="padding-bottom: 62.5%">
                            <img
                                class="article-image"
                                loading="lazy"
                                srcSet={imageUrls.map(
                                    ({ url, width }) => `${url} ${width}w`,
                                )}
                                sizes="400px"
                                alt=""
                            />
                        </div>
                    </figure>
                    <div className="titles">
                        {subTitle ? (
                            <span className="insert-title">{subTitle}</span>
                        ) : null}
                        <h2 class="headline" arial-label={title}>
                            {titleLines.map((titleLine, i) => (
                                <span
                                    key={i}
                                    className="d-block"
                                    style="font-size: 1px;"
                                >
                                    {titleLine}
                                </span>
                            ))}
                        </h2>
                    </div>
                </a>
            </div>
        </article>
    );
};

const renderWidget = (trending) => {
    const [first, second, third] = trending;

    return (
        <div className="container container-package">
            <div className="header" aria-label="Mest lest siden sist">
                <div className="icon-wrapper">
                    <img
                        className="icon"
                        src={`${__webpack_public_path__}/gfx/icons/bullet.svg`}
                    />
                </div>
                <h2 className="title">Mest lest siste 24t</h2>
            </div>
            <div className="articles">
                <Article
                    article={first.article}
                    width="three-fifth"
                    style={`grid-row: span 2;`}
                />
                <Article article={second.article} width="two-fifth" />
                <Article article={third.article} width="two-fifth" />
            </div>
        </div>
    );
};

function initTracking(elm, trending) {
    elm.addEventListener('click', (ev) => {
        const linkElm = ev.target.closest('a');
        if (!linkElm) {
            return;
        }

        trackEngagement({
            elementId: `mestlest-personalized`,
            elementType: 'mestlest',
            action: 'Click',
            intent: 'Open',
            target: {
                url: linkElm.href,
            },
            event: ev,
        });
    });

    onInView(elm, {
        threshold: 0.1,
    }).then(() => {
        trackEngagement({
            elementId: 'mestlest-personalized',
            elementType: 'mestlest',
            action: 'Scroll',
            intent: 'View',
            elements: trending.map(
                ({ article: { id, newsroom, url, title } }, i) => ({
                    '@id': `sdrn:vg:frontpage:front:element:mestlest-${id}`,
                    size: i == 0 ? 0.6 : 0.4,
                    target: {
                        '@id': `sdrn:${newsroom}:article:${id}`,
                        '@type': 'article',
                        name: title,
                        url,
                    },
                }),
            ),
        });
    });
}

function shouldRender() {
    const settings = new UserSettings();
    return settings.get('show_most_read').catch(() => true);
}

async function mestlest() {
    const trendingUrl =
        'https://user-segmentation-api.prod.sk8s.vg.no/v1/trending?teaserPackage=true';

    const mestlestElm = document.querySelector('#mestlest-placeholder');
    const { sig } = await hasSession().catch(() => ({}));
    if (!sig) {
        mestlestElm.style.display = 'none';
        return;
    }

    if (!(await shouldRender())) {
        mestlestElm.style.display = 'none';
        return;
    }

    const headers = {
        authorization: `Bearer ${sig}`,
    };
    const trending = await fetch(trendingUrl, { headers }).then((res) =>
        res.json(),
    );

    if (!trending || !trending.length) {
        return;
    }

    render(renderWidget(trending), mestlestElm);

    // make each headline line fill width
    const updateSpanStyles = (spans, fontSizes, display = null) => {
        spans.forEach((span, i) => {
            Object.assign(span.style, {
                display,
                fontSize: Array.isArray(fontSizes) ? fontSizes[i] : fontSizes,
            });
        });
    };
    mestlestElm.querySelectorAll('.headline').forEach((headlineElm) => {
        const articleSkin = (/skin-(\S+)/.exec(
            headlineElm.closest('.article')?.className,
        ) || [])[1];
        const headlineWidth = headlineElm.clientWidth - 4;
        const spans = $$('span', headlineElm);
        updateSpanStyles(spans, '16px', 'inline-block');
        const lineWidths = spans.map(
            (spanElm) => spanElm.getBoundingClientRect().width,
        );
        if (['column', 'op-ed', 'editorial', 'review'].includes(articleSkin)) {
            const maxLineWidth = Math.max(...lineWidths);
            const fontSize = `${Math.floor(
                headlineWidth / (maxLineWidth / 16),
            )}px`;
            updateSpanStyles(spans, fontSize);
        } else {
            updateSpanStyles(
                spans,
                lineWidths.map(
                    (lineWidth) =>
                        `${Math.floor(headlineWidth / (lineWidth / 16))}px`,
                ),
            );
        }
    });

    initTracking(mestlestElm, trending);
}

export default function initMestLest() {
    const placeholderElm = document.querySelector('#mestlest-placeholder');

    onInView(placeholderElm, {
        threshold: 0,
        rootMargin: '1000px 0px',
    }).then(() => {
        mestlest();
    });
}
