// @ts-nocheck
import { render } from 'preact';
import { useState } from 'preact/hooks';
import s from '../../sass/widgets/retargeting-widget.module.scss';

const Widget = ({ placement }) => {
    const [showDescription, setShowDescription] = useState(false);
    const [activeDescriptionIdx, setActiveDescriptionIdx] = useState(null);

    const handleClick = (e) => {
        const description = placement.filter(
            (el) => el.editorial?.name === e.target.value,
        );
        setActiveDescriptionIdx(description);
        setShowDescription(!showDescription);
    };

    const hideDescription = () => {
        setShowDescription(!showDescription);
    };

    const ComponentName = ({ option }) => {
        return (
            <>
                {option?.map((component) => (
                    <h2 key={component}>
                        {component.componentName}
                        {option?.length > 1 && `,`}
                    </h2>
                ))}
            </>
        );
    };

    return (
        <div className={s.root}>
            {!showDescription && (
                <>
                    <h1 className={s.title}>Personalisering</h1>
                    <div className={s.buttons}>
                        {placement.map(
                            (el) =>
                                el.editorial?.name && (
                                    <button
                                        onClick={handleClick}
                                        key={el}
                                        value={el.editorial?.name}
                                        type="button"
                                    >
                                        {el.editorial?.name}
                                    </button>
                                ),
                        )}
                    </div>
                </>
            )}

            {activeDescriptionIdx?.map(
                (el) =>
                    showDescription && (
                        <div className={s.content} key={el}>
                            <header>
                                <h1 className={s.title}>
                                    {el.editorial?.name}
                                </h1>
                                <button onClick={hideDescription} type="button">
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M2.86177 3.80476C2.60142 3.54441 2.60142 3.1223 2.86177 2.86195C3.12212 2.6016 3.54423 2.6016 3.80457 2.86195L7.99984 7.05721L12.1951 2.86195C12.4554 2.6016 12.8776 2.6016 13.1379 2.86195C13.3983 3.1223 13.3983 3.54441 13.1379 3.80476L8.94265 8.00002L13.2327 12.29C13.493 12.5504 13.493 12.9725 13.2327 13.2328C12.9723 13.4932 12.5502 13.4932 12.2899 13.2328L7.99984 8.94283L3.80457 13.1381C3.54423 13.3984 3.12212 13.3984 2.86177 13.1381C2.60142 12.8777 2.60142 12.4556 2.86177 12.1953L7.05703 8.00002L2.86177 3.80476Z"
                                            fill="#00174D"
                                        />
                                    </svg>
                                </button>
                            </header>
                            <div className={s.components}>
                                {el?.components?.variant && (
                                    <ComponentName
                                        option={el?.components?.variant}
                                    />
                                )}

                                {el?.components?.a && (
                                    <ComponentName option={el?.components?.a} />
                                )}

                                {el?.components?.var_a && (
                                    <ComponentName
                                        option={el?.components?.var_a}
                                    />
                                )}

                                {el?.components?.standard && (
                                    <ComponentName
                                        option={el?.components?.standard}
                                    />
                                )}
                            </div>
                            <p>{el.editorial?.description}</p>
                            <div class={s.buttonRow}>
                                <a
                                    href={el.editorial?.forced_preview_url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Se eksempel
                                </a>
                                {el.editorial?.readme_url && (
                                    <a
                                        href={el.editorial?.readme_url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Les mer
                                    </a>
                                )}
                            </div>
                        </div>
                    ),
            )}
        </div>
    );
};

async function retargetingPreview() {
    const retargetingUrl =
        'https://retargeting-sandbox.k8s.vgnett.no/_editorial';

    const retargeting = await fetch(retargetingUrl).then((res) => res.json());

    const front = retargeting.find(({ config }) => config === 'front');
    const placements = Object.keys(front.placements);

    placements.forEach((placementName) => {
        const retargetingElm = document.querySelector(`#${placementName}`);

        const placement = front.placements[placementName].slice(0, 7);

        if (!retargeting || !retargeting.length) {
            return;
        }

        if (placement.length > 0) {
            render(<Widget placement={placement} />, retargetingElm);
        }
    });
}

export default function initRetargetingWidget() {
    retargetingPreview();
}
